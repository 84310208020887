import { Route, Routes } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectIsLogin, selectUserAuthority } from './Store/selectors';
import { useEffect, lazy, Suspense } from 'react';
import { bindActionCreators } from 'redux';
import { setIsLogin, setUserAuthority } from './Store/actions';
import { API } from './API/Api';
import Loading from './Components/Loading/Loading';

const LoginLazy = lazy(() => import('./Components/Login/Login'));
const HomePageLazy = lazy(() => import('./Components/HomePage/HomePage'));
const InNotFoundLazy = lazy(() => import('./Components/NotFound/InNotFound'));
const NotInNotFoundLazy = lazy(() => import('./Components/NotFound/NotInNotFound'));
const MyInformationLazy = lazy(() => import('./Components/MyInformation/MyInformation'));
const UserListLazy = lazy(() => import('./Components/Users/UserList/UserList'));
const UserUpdateLazy = lazy(() => import('./Components/Users/UserUpdate/UserUpdate'));
const AddUserLazy = lazy(() => import('./Components/Users/AddUser/AddUser'));
const LokomotiveListLazy = lazy(() => import('./Components/Lokomotives/LokomotiveList/LokomotiveList'));
const AddLokomotiveLazy = lazy(() => import('./Components/Lokomotives/AddLokomotive/AddLokomotive'));
const LokomotiveUpdateLazy = lazy(() => import('./Components/Lokomotives/LokomotiveUpdate/LokomotiveUpdate'));
const MaintenanceListLazy = lazy(() => import('./Components/Maintenances/MaintenancesList/MaintenanceList'));
const MaintenanceAddLazy = lazy(() => import('./Components/Maintenances/MaintenanceAdd/MaintenanceAdd'));
const MaintenanceUpdateLazy = lazy(() => import('./Components/Maintenances/MaintenanceUpdate/MaintenanceUpdate'));
const PastMaintenancesListLazy = lazy(() => import('./Components/PastMaintenances/PastMaintenancesList/PastMaintenancesList'));
const ChangePasswordLazy = lazy(() => import('./Components/ChangePassword/ChangePassword'));
const FilterLazy = lazy(() => import('./Components/Filter/Filter'));
const DocumentsLazy = lazy(() => import('./Components/Documents/Documents'));
const LokoRemainKmLazy = lazy(() => import('./Components/LokoRemainKm/LokoRemainKm'));
const LokomotiveKmListLazy = lazy(() => import('./Components/LokomotiveKm/LokomotiveKmList/LokomotiveKmList'));
const LokomotiveKmSendDataLazy = lazy(() => import('./Components/LokomotiveKm/LokomotiveKmSendData/LokomotiveKmSendData'));
const LokomotiveKmLimitLazy = lazy(() => import('./Components/LokomotiveKm/LokomotiveKmLimit/LokomotiveKmLimit'));


axios.defaults.withCredentials = true;
function App(props) {

  useEffect(() => {
    getLoggedIn();
  }, []);

  const getLoggedIn = async () => {
    await axios.get(API + "/authentication/loggedIn")
      .then(async (response) => {
        await props.setUserAuthority(response.data.authority)
      })
      .catch(() => {
        props.setIsLogin(false)
      })

  }

  return (
    <Suspense fallback={<Loading />}>
      <Routes >
        {
          props.isLogin ?
            <>
              <Route path="/" element={<HomePageLazy />} />
              <Route path="login" element={<LoginLazy />} />
              <Route path="my-information" element={<MyInformationLazy />} />
              <Route path="maintenance/list" element={<MaintenanceListLazy />} />
              <Route path="maintenance/add" element={<MaintenanceAddLazy />} />
              <Route path="maintenance/update/:id" element={<MaintenanceUpdateLazy />} />
              <Route path="past-maintenances/list" element={<PastMaintenancesListLazy />} />
              <Route path="change-password" element={<ChangePasswordLazy />} />
              <Route path="filter-past-maintenance" element={<FilterLazy />} />
              <Route path="documents" element={<DocumentsLazy />} />

              {
                props.userAuthority === "Manager" ? <>
                  <Route path="lokomotive/list" element={<LokomotiveListLazy />} />
                  <Route path="lokomotive/add" element={<AddLokomotiveLazy />} />
                  <Route path="lokomotive/update/:id" element={<LokomotiveUpdateLazy />} />
                  <Route path="users/list" element={<UserListLazy />} />
                  <Route path="users/add" element={<AddUserLazy />} />
                  <Route path="user/update/:id" element={<UserUpdateLazy />} />
                  <Route path="remain-km" element={<LokoRemainKmLazy />} />
                  <Route path="remain-km/list" element={<LokomotiveKmListLazy />} />
                  <Route path="remain-km/send-data" element={<LokomotiveKmSendDataLazy />} />
                  <Route path="remain-km/limit" element={<LokomotiveKmLimitLazy />} />
                </> : null
              }
              <Route path='*' element={<InNotFoundLazy />} />
            </>
            :
            <>
              <Route path="login" element={<LoginLazy />} />
              <Route path='*' element={<NotInNotFoundLazy />} />
            </>
        }
      </Routes>
    </Suspense>
  );
}

const mapStateToProps = createStructuredSelector({
  isLogin: selectIsLogin(),
  userAuthority: selectUserAuthority()

});

const mapDispatchToProps = (dispatch) => (
  (
    bindActionCreators({ setIsLogin, setUserAuthority }, dispatch)
  )
)

export default connect(mapStateToProps, mapDispatchToProps)(App);
