import { createSelector } from "reselect";

const domain = state => state;

export const selectIsLogin = () => createSelector(
    domain,
    substate => substate.isLogin,
);

export const selectKmLimit = () => createSelector(
    domain,
    substate => substate.kmLimit,
);

export const selectUserAuthority = () => createSelector(
    domain,
    substate => substate.userAuthority,
)

export const selectPastMaintenanceFilterList = () => createSelector(
    domain,
    substate => substate.pastMaintenanceFilterList,
)