import * as constants from './constants';

export const setIsLogin = isLogin => ({
    type: constants.IS_LOGIN,
    payload: isLogin
});

export const setPastMaintenancesFilterList = filterList => ({
    type: constants.SET_FILTER_PAST_MAINTENANCE_LIST,
    payload: filterList
});

export const setKmLimit = state => ({
    type: constants.SET_KM_LIMIT,
    payload: state
});

export const setUserAuthority= authority => ({
    type: constants.SET_USER_AUTHORITY,
    payload: authority
});

export const setDefaultValues = () => ({
    type: constants.SET_DEFAULT_VALUES
});