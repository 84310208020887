import React from 'react'

function Loading() {
  return (
    <div className='loading-page'>
        <div className="body">
            Sayfa Yükleniyor...
        </div>
    </div>
  )
}
export default Loading;